<template>
  <div>
    <div v-if="!fetchingData" class="font-weight-bold">{{ companyName }}</div>
    <v-progress-linear
      v-else
      color="primary"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CompanyNameById",

  props: ["id"],

  created: function() {
    if (this.id) {
      this.getData();
    }
  },

  data() {
    return {
      companyName: "",
      fetchingData: false,
    };
  },
  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(`companies/${this.id}`);
      this.fetchingData = false;

      if (response.status === 200) {
        this.companyName = response.data.data[0].name;
      }
    },
  },
  mixins: [apiRequest],
};
</script>
