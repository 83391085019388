<template>
  <div>
    <v-chip
      class="ma-2"
      v-if="item.status === 'isOpen' || item.status === '' || !item.status"
      color="red"
      dark
      >Offen<v-icon small right>mdi-alert-circle</v-icon></v-chip
    >
    <v-chip
      class="ma-2"
      v-else-if="item.status === 'isContacted'"
      color="orange"
      dark
      >Kontaktiert</v-chip
    >
    <v-chip
      class="ma-2"
      v-else-if="item.status === 'isInterested'"
      color="yellow"
      >Interessiert</v-chip
    >
    <v-chip
      class="ma-2"
      v-else-if="item.status === 'offerSent'"
      color="pink"
      dark
      >Angebot gesendet<v-icon small right>mdi-alert-circle</v-icon></v-chip
    >
    <v-chip
      class="ma-2"
      v-else-if="item.status === 'isScheduled'"
      color="purple"
      dark
      >Terminiert</v-chip
    >
    <v-chip class="ma-2" v-else-if="item.status === 'isLost'" color="black" dark
      >Verloren</v-chip
    >
    <v-chip class="ma-2" v-else-if="item.status === 'isWon'" color="green" dark
      >Gewonnen</v-chip
    >
  </div>
</template>

<script>
/* Pitch-State dataTable <template> slot */
export default {
  name: "TablePitchState",

  props: ["item"],

  data() {
    return {};
  },
};
</script>
