<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      class="mt-1"
      v-for="product in item.productsArray"
      :key="product.id"
    >
      <v-chip
        v-if="product.isApproved === '1'"
        class="mb-1"
        color="green"
        label
        text-color="white"
      >
        <v-icon left>
          mdi-checkbox-marked-circle-outline
        </v-icon>
        {{ product.name }}
      </v-chip>

      <v-chip v-else class="mb-1" color="red" label text-color="white">
        <v-icon left>
          mdi-checkbox-blank-circle-outline
        </v-icon>
        {{ product.name }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
/* Pitch-State dataTable <template> slot */
export default {
  name: "TableProductsObject",

  props: ["item"],

  data() {
    return {};
  },
};
</script>
