<template>
  <v-chip v-if="isReady" color="green" class="white--text ma-1">
    <v-icon small left>
      mdi-checkbox-marked-circle-outline
    </v-icon>
    Eingerichtet
  </v-chip>
  <v-chip v-else color="red" class="white--text">
    <v-icon small left>
      mdi-information-outline
    </v-icon>
    Unvollständig
  </v-chip>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CampaignSetupState",

  components: {},

  props: ["item"],

  data() {
    return {
      fetchingData: false,
      isReady: false,
    };
  },

  created: function() {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      const response = await this.getRequest(
        `/campaigns/${this.item.id}/isReady`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.isReady = response.data.data.isReady;
      }
    },
  },
  mixins: [apiRequest],
};
</script>
