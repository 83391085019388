<template>
  <v-item-group class="d-flex flex-row">
    <div v-if="itemActions.edit">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="green"
            @click="emitAction('editItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
    </div>

    <div v-if="itemActions.leads">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="blue"
            @click="emitAction('leadsItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
          >
            <v-icon dark>mdi-account-details</v-icon>
          </v-btn>
        </template>
        <span>Leads anzeigen</span>
      </v-tooltip>
    </div>

    <div v-if="itemActions.preview">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="blue"
            @click="emitAction('previewItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
            :disabled="item.pages_id && item.pages_id !== '' ? false : true"
          >
            <v-icon dark>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Vorschau</span>
      </v-tooltip>
    </div>

    <div v-if="itemActions.stats">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="purple"
            @click="emitAction('statsItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
          >
            <v-icon dark>mdi-chart-line-variant</v-icon>
          </v-btn>
        </template>
        <span>Statistiken</span>
      </v-tooltip>
    </div>

    <div v-if="itemActions.copy">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="orange"
            @click="emitAction('copyItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
          >
            <v-icon dark>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Duplizieren</span>
      </v-tooltip>
    </div>

    <div v-if="itemActions.trash">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="red"
            @click="emitAction('deleteItem')"
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            elevation="0"
          >
            <v-icon dark>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </div>
  </v-item-group>
</template>

<script>
/* Pitch-State dataTable <template> slot */
export default {
  name: "TableActionButtons",

  props: ["item", "itemActions", "index"],

  data() {
    return {};
  },

  methods: {
    emitAction(action) {
      this.$emit("action", {
        action: action,
        itemId: this.item.id,
        itemIndex: this.index,
        itemData: this.item,
        item: this.item,
      });
    },
  },
};
</script>
